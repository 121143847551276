.aboutme {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10vmin;
  padding-bottom: 10vmin;
}

.aboutme-paragraph {
  text-align: center;
  color: white;
  margin-inline: 10vmin;
}
