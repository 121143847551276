.about-intro {
  margin: 5vmin;
  margin-top: 10vmin;
  color: white;
  font-size: 6vmin;
}

.about-name {
  border-radius: 50px;
  padding: 5px;
  padding-inline: 30px;
  background-color: #ffc242;
  color: #282c34;
  margin: 5vmin;
  font-size: 10vmin;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  transition: all 100ms ease-out 50ms;
}

.about-name:hover {
  color: #ffc242;
  background-color: transparent;
  font-size: 15vmin;
}

.about-title-intro {
  margin: 5vmin;
  margin-bottom: 10vmin;
  color: white;
  font-size: 6vmin;
}

.about-title {
  color: #ffc242;
  font-size: 6vmin;
  transition: all 100ms ease-out 50ms;
}

.about-title:hover {
  font-size: 10vmin;
}
