.project-tabs-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10vmin;
}

.tab-description {
  text-align: center;
  margin-bottom: 2vmin;
}
