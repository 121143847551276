.footer {
  background-color: #003185;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 2vmin;
  max-height: 10vh;
}

.socials {
  max-height: 8vh;
  background-color: white;
  padding: 5px;
  border-radius: 5%;
}
