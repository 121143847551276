.navbar {
  position: fixed;
  max-height: 10vh;
  width: 100vw;
  background-color: #004ba5;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 2vmin;
}

.logo {
  max-height: 100px;
  height: 6vmin;
  margin-right: 2vmin;
}
