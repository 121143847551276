.projects {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.project-cards {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-evenly;
}
